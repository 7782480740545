import * as React from "react";
import { SVGProps } from "react";

const SvgLogoPiklibColored = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={787}
        height={266}
        viewBox="0 0 787 266"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M310.626 222.355V83.8629H332.589L334.131 96.959C341.644 86.1719 354.163 81.7434 364.95 81.7434C394.227 81.7434 413.682 103.507 413.682 131.44C413.682 159.373 396.157 181.515 365.717 181.515C355.507 181.515 341.058 178.241 334.131 167.644V222.346H310.635L310.626 222.355ZM390.177 131.44C390.177 116.612 380.166 104.472 363.209 104.472C346.253 104.472 336.241 116.603 336.241 131.44C336.241 146.277 347.029 158.597 363.209 158.597C379.39 158.597 390.177 146.268 390.177 131.44Z"
            fill="#021964"
        />
        <path
            d="M445.077 43.42C452.013 43.42 458.949 48.0469 458.949 57.0933C458.949 66.1397 452.013 70.7666 445.077 70.7666C438.142 70.7666 431.206 66.3381 431.206 57.0933C431.206 47.8485 438.142 43.42 445.077 43.42ZM456.83 83.4839V178.827H433.334V83.4839H456.83Z"
            fill="#021964"
        />
        <path
            d="M505.75 44.1862V122.005L537.724 83.8714H565.846V85.2153L527.135 128.553L571.24 177.095V178.827H542.928L505.75 135.877V178.827H482.255V44.1862H505.75Z"
            fill="#021964"
        />
        <path d="M608.031 44.1862V178.827H584.725V44.1862H608.031Z" fill="#021964" />
        <path
            d="M645.786 43.42C652.722 43.42 659.658 48.0469 659.658 57.0933C659.658 66.1397 652.722 70.7666 645.786 70.7666C638.85 70.7666 631.914 66.3381 631.914 57.0933C631.914 47.8485 638.85 43.42 645.786 43.42ZM657.538 83.4839V178.827H634.043V83.4839H657.538Z"
            fill="#021964"
        />
        <path
            d="M706.459 44.1865V96.7692C712.241 86.7488 727.456 81.5536 737.666 81.5536C765.401 81.5536 786.01 98.501 786.01 131.25C786.01 162.259 764.823 181.326 737.089 181.326C725.535 181.326 713.972 177.276 706.459 165.722L704.917 178.818H682.955V44.1774H706.45L706.459 44.1865ZM708.001 131.25C708.001 148.396 720.71 159.183 735.547 159.183C750.383 159.183 762.514 147.819 762.514 131.25C762.514 114.682 750.383 103.516 735.547 103.516C720.71 103.516 708.001 114.88 708.001 131.25Z"
            fill="#021964"
        />
        <path
            d="M0.00900063 238.426L57.2187 135.57C60.8175 129.103 70.2517 129.482 73.3093 136.228L97.6976 189.929L113.68 164.441C117.351 158.587 125.982 158.867 129.256 164.955L183.616 265.782V0L0 27.058V238.426H0.00900063ZM132.945 43.4191C141.865 43.4191 149.09 53.6832 149.09 66.3553C149.09 79.0275 141.865 89.2915 132.945 89.2915C124.025 89.2915 116.801 79.0275 116.801 66.3553C116.801 53.6832 124.034 43.4191 132.945 43.4191ZM0.559195 238.796L0 238.426V238.715L0.559195 238.796ZM248.267 21.3578V244.415L265.521 238.715V27.058L248.267 21.3578ZM220.965 253.435L238.219 247.734V18.0387L220.965 12.3385V253.435ZM193.663 262.454L210.917 256.754V9.01935L193.663 3.31911V262.454Z"
            fill="url(#paint0_linear_4747_51872)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_4747_51872"
                x1={-0.00000635034}
                y1={163.5}
                x2={266}
                y2={163.5}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4CCFFF" />
                <stop offset={0.78125} stopColor="#063EF9" />
                <stop offset={0.994792} stopColor="#021964" />
            </linearGradient>
        </defs>
    </svg>
);

export default SvgLogoPiklibColored;
